export default ({
    successTextColor,
    successBackground,
    successDarkOverlay,
    successPreviousColor,
    successBorderColor,
}) => ({
    success: {
        color: successTextColor,
        background: successBackground,
        darkOverlay: successDarkOverlay,
        previousColor: successPreviousColor,
        borderColor: successBorderColor,
    },
});
