import { rgba as addAlpha } from 'polished';
import {
    OctopusBlack,
    DirectDebitLogoWhite,
    DirectDebitLogo,
    OctopusElectricityIcon,
    PhoneIconOE,
    FacebookLogo,
    XLogo,
    WhatsappLogo,
    EmailIcon,
    OEIcon,
    ConstantineFinder,
    SmartModalBackground,
} from 'octo-common-img';
import colorsMixin from './colors';
import buttonMixin from './components/button';
import outlineContainerMixin from './components/outlineContainer';
import cardMixin from './components/card';
import tileMixin from './components/tile';
import pillMixin from './components/pill';
import switchMixin from './components/switch';
import sidebarMixin from './components/quote/sidebar';
import readyToGoMixin from './components/readyToGo';
import {
    dropdownMixin,
    inputMixin,
    multiSelectMixin,
    radioButtonMixin,
    checkboxMixin,
} from './components/form';
import tariffMixin from './components/quote/tariff';
import createAccountMixin from './components/createAccount';
import {
    meterReadingHistoryMixin,
    meterReadingSubmissionMixin,
    referralsMixin,
    smartMeterConsumptionMixin,
    paymentDetailsMixin,
    billsMixin,
    transactionHistoryMixin,
    updateTariffsMixin,
    paginationMixin,
    debtRepaymentOptionsMixin,
} from './components/account-management';
import { accountLookupMixin } from './components/occupy';
import tariffChangeMixin from './components/tariffChange';
import emphasisMixin from './components/emphasis';
import productCardsMixin from './components/product-explorer/productCards';
import helpTextMixin from './components/helpText';

/* Base shades */
const white = '#ffffff';
const black = '#000000';

/* Octopus Energy brand colours */
const brandColors = {
    hemocyanin: '#180048',
    siphon: '#100030',
    sohoLights: '#f050f8',
    akoya: '#f484fa',
    mulberry: '#bb00d4',
    ink: '#5840ff',
    blueTang: '#6675f6',
    blueberry: '#2d1a83',
    voltage: '#60f0f8',
    aquamarine: '#bdf2ff',
    ice: '#f0ffff',
    dustySky: '#d9ddff',
    purpleHaze: '#a49fc8',
    green: '#18f0b8',
    red: '#ff3e68',
    oranda: '#f8c050',
    electricPurple: '#6018c8',
    lilac: '#b389f1',
    blackberry: '#340d6e',
};

/* Standardised colour object */
const colors = colorsMixin({
    error: brandColors.red,
    warning: brandColors.oranda,
    success: brandColors.green,
    link: brandColors.voltage,
    linkHover: brandColors.aquamarine,
    linkDark: brandColors.ink,
    linkDarkHover: brandColors.blueberry,
    backgroundPrimary: brandColors.siphon,
    backgroundSecondary: brandColors.hemocyanin,
    backgroundLight: brandColors.ice,
    textPrimary: brandColors.ice,
    textSecondary: brandColors.ice,
    textLight: brandColors.ice,
    textDark: brandColors.siphon,
    accentPrimary: brandColors.sohoLights,
    accentSecondary: brandColors.ink,
    accentTertiary: brandColors.voltage,
    greyBase: '#809298',
    greyDark: '#272735',
    greyMedium: '#696879',
    greyLight: brandColors.dustySky,
});

// component constants
const buttonLinkBoxShadow = `0 0.2rem 0.4rem 0.2rem ${addAlpha(
    colors.text.dark,
    0.24
)}`;
const borderRadius = '.8rem';
const tariffInfoButtonBackgroundColor = '#6236FF';

const commonButtonStyles = {
    padding: '2rem',
    fontSize: '2.25rem',
    fontSizeSmallScreens: '1.6rem',
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: borderRadius,
    boxShadow: buttonLinkBoxShadow,
    disabled: {
        color: brandColors.purpleHaze,
        background: brandColors.mulberry,
        border: `0.2rem solid ${brandColors.mulberry}`,
        hover: {
            color: brandColors.ice,
        },
    },
    focus: {
        border: `0.2rem solid ${brandColors.ice}`,
    },
};

const buttonPrimary = buttonMixin({
    ...commonButtonStyles,
    color: colors.text.dark,
    background: colors.accent.primary,
    border: `0.2rem solid ${colors.accent.primary}`,
    hover: {
        background: brandColors.akoya,
        border: `0.2rem solid ${brandColors.akoya}`,
    },
    focus: {
        ...commonButtonStyles.focus,
        background: brandColors.akoya,
    },
});

const buttonSecondary = buttonMixin({
    ...commonButtonStyles,
    color: colors.text.light,
    background: colors.accent.secondary,
    border: `0.2rem solid ${colors.accent.secondary}`,
    hover: {
        background: brandColors.blueTang,
        border: `0.2rem solid ${brandColors.blueTang}`,
    },
    focus: {
        ...commonButtonStyles.focus,
        background: brandColors.blueTang,
    },
    disabled: {
        ...commonButtonStyles.disabled,
        background: brandColors.blueberry,
        border: `0.2rem solid ${brandColors.blueberry}`,
    },
});

const transactionHistory = transactionHistoryMixin({
    configurableBackground: brandColors.ice,
    configurableTextColor: colors.background.secondary,
    activityStreamBackgroundColor: colors.background.primary,
    activityStreamColor: colors.accent.secondary,
    borderColor: colors.accent.secondary,
    borderWidth: '0.3rem',
    pendingTextColor: '#d8d8d8',
    pendingBorder: colors.accent.secondary,
    summaryBackground: colors.background.primary,
    summaryDarkOverlay: colors.background.secondary,
    hyperlinkColor: colors.nav.link,
    supplyStartIcon: OEIcon,
    consumptionDateRangeColor: colors.grey.medium,
});

export default {
    name: 'Octopus Energy',
    styleguide: {
        brandColors,
        borderRadius,
    },
    colour: {
        ...colors,
        focusRing: colors.accent.primary,
        white,
        black,
        table: {
            rowBackground: colors.grey.light,
        },
    },

    fontFamily: {
        default: 'Chromatophore, helvetica, arial, sans-serif',
    },

    newStyles: {
        button: {
            primary: buttonPrimary,
            secondary: buttonSecondary,
        },
    },

    // Components
    pendingBalance: {
        color: brandColors.purpleHaze,
    },

    quoteSectionHeader: {
        h2: {
            fontSize: '1.8rem',
            fontWeight: '400',
            textTransform: 'uppercase',
            letterSpacing: '0.06rem',
        },
        p: {
            fontSize: '1.2rem',
            fontWeight: '200',
            letterSpacing: '0.05rem',
        },
    },

    outlineContainer: outlineContainerMixin({
        backgroundColor: colors.accent.tertiary,
        contentBackgroundColor: {
            default: colors.background.primary,
            selected: brandColors.hemocyanin,
        },
        borderColor: {
            default: colors.accent.secondary,
            selected: `${colors.accent.tertiary}`,
        },
    }),

    button: {
        ...buttonPrimary,
    },

    dashboardCard: {
        borderColor: brandColors.blueberry,
    },

    multiEmailQuote: {
        backgroundColor: 'transparent',
        titleFontSize: '2.8rem',
        titleFontWeight: '300',
        textAlign: 'center',
        successOverlayColour: addAlpha(colors.action.success, 0.9),
        hasBorder: true,
    },

    multiSelectButton: {
        primary: multiSelectMixin({
            padding: '1.3rem 0.4rem',
            borderRadius: borderRadius,
            deselectedColor: brandColors.ice,
            deselectedBorder: `0.2rem solid ${colors.accent.tertiary}`,
            selectedColor: black,
            selectedBackgroundColor: colors.accent.tertiary,
            selectedBorder: `0.2rem solid ${colors.accent.tertiary}`,
            joinedFirstBorder: `${borderRadius} 0 0 ${borderRadius}`,
            joinedLastBorder: `0 ${borderRadius} ${borderRadius} 0`,
            joinedBorderRadius: '0',
        }),
        secondary: multiSelectMixin({
            containerBorder: `0.1rem solid ${colors.accent.secondary}`,
            containerBorderRadius: '1rem',
            containerBoxSizing: 'content-box',
            padding: '0 0.4rem',
            borderRadius: borderRadius,
            deselectedColor: brandColors.ice,
            deselectedBackgroundColor: colors.background.primary,
            deselectedBorder: `0.2rem solid ${colors.accent.secondary}`,
            selectedColor: white,
            selectedBackgroundColor: colors.accent.secondary,
            selectedBorder: '0',
            joinedFirstBorder: borderRadius,
            joinedLastBorder: borderRadius,
            joinedBorderRadius: borderRadius,
            joinedDeselectedBorder: '0',
            border: `.1rem solid ${colors.accent.secondary}`,
        }),
    },

    productExplorer: {
        card: {
            primary: productCardsMixin({
                color: colors.text.primary,
                backgroundColor: colors.background.secondary,
                badgeColor: colors.action.success,
                borderRadius: '1.6rem',
                border: `0.1rem solid ${colors.accent.secondary}`,
                hoverColor: colors.accent.tertiary,
            }),
            secondary: productCardsMixin({
                color: white,
                backgroundColor: black,
                badgeColor: colors.action.error,
                borderRadius: '1.6rem',
                hoverColor: colors.accent.tertiary,
            }),
        },
        badge: {
            borderRadius: '0.8rem',
            fontSize: '1.5rem',
        },
    },

    productBadge: {},

    progressBar: {
        boxShadow: `inset 0.4rem 0.2rem 0.4rem 0 ${addAlpha(black, 0.5)}`,
    },

    help: {
        backgroundColor: colors.accent.secondary,
        color: colors.text.light,
        phone: PhoneIconOE,
        button: {
            backgroundColor: white,
            color: colors.accent.secondary,
        },
    },

    helpText: helpTextMixin({
        color: brandColors.ink,
        size: '1.2rem',
        style: 'normal',
    }),

    heroCard: {
        primary: cardMixin({
            borderRadius: '0.4rem',
            boxShadow: `inset 0 0.2rem 0.4rem 0.2rem ${addAlpha(
                colors.text.dark,
                0.6
            )}`,
        }),
        secondary: cardMixin({
            backgroundColor: colors.accent.secondary,
        }),
        tertiary: cardMixin({
            backgroundColor: colors.accent.tertiary,
        }),
    },
    card: {
        primary: cardMixin({
            backgroundColor: colors.background.secondary,
            border: `2px solid ${colors.accent.primary}`,
            borderRadius: '1.6rem',
            label: colors.accent.primary,
        }),
        secondary: cardMixin({
            backgroundColor: colors.background.secondary,
            border: `2px solid ${colors.accent.secondary}`,
            borderRadius: '1.6rem',
            label: colors.accent.secondary,
        }),
        tertiary: cardMixin({
            backgroundColor: colors.background.secondary,
            border: `2px solid ${colors.accent.tertiary}`,
            borderRadius: '1.6rem',
            label: colors.accent.tertiary,
        }),
    },

    type: {
        h5: {
            letterSpacing: '-0.01rem',
        },
        link: {},
    },

    emphasis: {
        primary: emphasisMixin({
            backgroundColor: colors.background.secondary,
            color: white,
            borderRadius: '1.6rem',
        }),
        secondary: emphasisMixin({
            backgroundColor: colors.background.secondary,
            color: white,
            borderRadius: '1.6rem',
        }),
        warning: emphasisMixin({
            backgroundColor: colors.background.secondary,
            color: white,
            borderRadius: '1.6rem',
            borderColor: colors.action.error,
        }),
        info: emphasisMixin({
            backgroundColor: colors.background.secondary,
            color: white,
            borderRadius: '1.6rem',
            borderColor: colors.accent.secondary,
        }),
    },

    cardModal: {
        backgroundColor: 'rgba(4, 14, 40, 0.9)',
        container: {
            dark: {
                backgroundColor: colors.background.secondary,
                color: white,
                input: {},
            },
        },
        buttonLinkSubtext: {
            dark: {},
        },
    },

    barGraph: {
        bar: {
            primary: colors.accent.tertiary,
            secondary: colors.accent.primary,
        },
        tooltip: {
            backgroundColor: colors.accent.secondary,
        },
    },

    // Quote
    quote: {
        bankPayments: {
            color: colors.action.success,
            tick: brandColors.sohoLights,
            detailsBorder: white,
            hintText: colors.action.success,
        },
        postcodeContainer: {},
        panel: {
            border: `0.2rem solid ${white}`,
            borderRadius: '0.4rem',

            content: {
                padding: '1.6rem',
                lastChildMarginBottom: '0',
            },

            title: {
                display: 'flex',
                alignItems: 'center',
                padding: '0.8rem 1.6rem 0.8rem 0.8rem',
                color: colors.background.primary,
                backgroundColor: white,

                before: {
                    display: 'inline-block',
                    width: '2.4rem',
                    height: '2.8rem',
                    marginRight: '0.4rem',
                    content: `url(${OctopusBlack})`,
                },
            },

            h3: {},
            paragraph: {},
        },
        submitButtonContainer: {},
        banner: {},
        bannerHeading: {},
        form: {},
        question: {
            heading: {},
            paragraph: {},
        },
        smartMeterBox: {
            backgroundColor: colors.background.secondary,
            emphasis: {},
            heading: {},
            paragraph: {},
        },
        trustPilotReview: {
            container: {
                color: colors.text.dark,
            },
            link: {
                color: colors.nav.linkDark,
            },
        },
        usage: {
            well: {},
            consumptionBox: {},
            question: {
                header: {},
            },
        },
        usageBar: {
            arrowColour: colors.accent.tertiary,
        },
    },

    // EmailQuoteContainer
    quoteEmail: {
        container: {},
        loadingMessage: {},
        loader: {
            bar: {
                border: `0.3rem solid ${colors.background.primary}`,
                backgroundColor: '#00b5ff',
                underlay: {
                    left: '-0.3%',
                    height: '2.5rem',
                },
            },
            marker: {
                display: 'none',
            },
        },
        form: {
            tile: {},
            hero: {
                color: black,
            },
            button: {
                disabled: {
                    color: colors.grey.base,
                    backgroundColor: '#cacfde',
                    border: `0.1rem solid ${colors.grey.light}`,
                },
            },
            link: {},
            // Temporairly using old colours here
            backgroundColor: '#f0ffff',
        },
        promo: {
            fontSize: '3rem',
            fontWeight: '700',
            lineHeight: '3.6rem',
            color: black,
        },
    },

    // Tariff Page
    tariff: tariffMixin({
        fontSize: '1.4rem',
        lineHeight: '2.4rem',
        cost: {
            fontSize: '1.8rem',
        },
        savings: {
            fontSize: '1.8rem',
        },
        projection: {
            defaultBackgroundColor: white,
            lightBackgroundColor: white,
        },
        card: {
            color: black,
            backgroundColor: white,
        },
        cardButton: {
            ...buttonSecondary,
            position: 'relative',
            bottom: '0',
            overlap: {
                position: 'absolute',
                bottom: '-3.3rem',
            },
        },
        tariffInfoButton: {
            color: white,
            backgroundColor: tariffInfoButtonBackgroundColor,
            borderColor: tariffInfoButtonBackgroundColor,
        },
        cardContent: {
            highlightColor: '#18f0b8',
        },
    }),

    // Tariff change app
    tariffChange: tariffChangeMixin({
        overview: {
            position: 'relative',
            zIndex: '2',
            padding: '1rem 1.4rem',
            marginBottom: '4.8rem',
            background: brandColors.hemocyanin,
            border: `0.2rem solid ${brandColors.sohoLights}`,
            borderRadius: '1.6rem',
            currentAmountMarginTop: '2rem',
        },
        personalProjection: {
            position: 'relative',
            width: 'auto',
            minHeight: '20rem',
            padding: '4.2rem 2rem',
            margin: '0 auto 3.8rem',
            color: `${colors.text.primary}`,
            backgroundColor: brandColors.hemocyanin,
            border: `0.1rem solid ${brandColors.ink}`,
            borderRadius: '1.6rem',
        },
    }),

    businessTariff: {
        selected: {
            background: colors.backgroundPrimary,
            border: `0.2rem solid ${brandColors.voltage}`,
            borderRadius: '0.4rem',
        },
    },

    // Meter Reading Submission
    mrs: meterReadingSubmissionMixin({
        lastReadingBorder: `0.1rem solid ${colors.grey.medium}`,
        lastReadingFigure: {
            afterColor: colors.grey.base,
        },
        fieldBackgroundColor: colors.background.secondary,
        newReadingFieldAfter: {
            color: colors.grey.base,
        },
        modalHr: {
            borderColor: colors.grey.base,
        },
    }),

    // Smart Meter Consumption
    smartMeterConsumption: smartMeterConsumptionMixin({
        electricityIcon: {
            afterBackgroundImage: OctopusElectricityIcon,
        },
        dateToggleButtonDisabledBackgroundColor: colors.grey.base,
        notFoundBackground: ConstantineFinder,
    }),

    // Meter Reading History
    mrh: meterReadingHistoryMixin({
        electricityImg: {
            backgroundImage: OctopusElectricityIcon,
        },
    }),

    // Prepay payment history
    prepayPaymentHistory: transactionHistory,

    // Balance history
    balanceHistory: transactionHistory,

    // Update tariffs
    updateTariffs: updateTariffsMixin({
        successTextColor: colors.background.secondary,
        successBackground: '#051d45',
        successDarkOverlay: '#050c27',
        successPreviousColor: white,
        successBorderColor: colors.accent.secondary,
    }),

    // Payment details
    paymentDetails: paymentDetailsMixin({}),

    referrals: referralsMixin({
        facebook: {
            backgroundColor: colors.social.facebook,
            backgroundImage: FacebookLogo,
        },
        twitter: {
            backgroundColor: colors.social.twitter,
            backgroundImage: XLogo,
        },
        whatsapp: {
            backgroundColor: colors.social.whatsapp,
            backgroundImage: WhatsappLogo,
        },
        email: {
            backgroundColor: colors.social.email,
            backgroundImage: EmailIcon,
        },
    }),

    createAccount: createAccountMixin({
        backgroundColor: colors.background.primary,
        sectionHeaderColour: colors.grey.light,
        pageHeaderTitle: {
            fontSize: '3.2rem',
        },
        pageHeaderSubtitle: {
            marginBottom: '.8rem',
            letterSpacing: '-0.2rem',
            fontSize: '3.2rem',
            fontWeight: 300,
            lineHeight: '3.4rem',
        },
        sectionTitle: {
            fontSize: '2.4rem',
            lineHeight: '3.6rem',
        },
        sectionSubtitle: {
            fontSize: '1.6rem',
            letterSpacing: '-0.1rem',
        },
        directDebitInstructionLogo: DirectDebitLogoWhite,
        directDebitInstructionLogoInverse: DirectDebitLogo,
        termsAndConditionsBorder: `.1rem solid ${colors.grey.base}`,
    }),

    consumptionPage: {
        periodComparisonCard: {
            datesColor: colors.grey.light,
            periodProgressDotBackgroundColor: brandColors.voltage,
        },
        downloadCard: {
            inspirationTextColor: brandColors.voltage,
        },
    },

    tooltip: {
        backgroundColor: colors.background.secondary,
    },

    checkbox: {
        primary: checkboxMixin({
            defaultCheckbox: {
                borderColor: colors.text.dark,
                borderRadius: '0.2rem',
                backgroundColor: white,
                boxShadow: 'inset 0 2px 3px -2px rgba(127, 127, 127, 0.5)',
                textColor: colors.text.dark,
            },
            focusedCheckbox: {
                backgroundColor: colors.accent.tertiary,
                borderColor: white,
            },
            checkedCheckbox: {
                backgroundColor: colors.accent.tertiary,
                color: black,
                borderColor: colors.accent.tertiary,
            },
        }),
        secondary: checkboxMixin({
            defaultCheckbox: {
                borderColor: colors.accent.primary,
                borderRadius: '0.2rem',
                boxShadow: 'inset 0 2px 3px -2px rgba(127, 127, 127, 0.5)',
                textColor: colors.accent.primary,
                backgroundColor: colors.background.primary,
            },
            focusedCheckbox: {
                backgroundColor: colors.background.primary,
                borderColor: white,
            },
            checkedCheckbox: {
                backgroundColor: colors.background.primary,
                color: colors.accent.primary,
                borderColor: colors.accent.primary,
            },
        }),
    },

    radio: {
        default: {
            borderColor: colors.text.dark,
        },
        hover: {
            borderColor: brandColors.grey,
        },
        focused: {
            borderColor: colors.accent.tertiary,
        },
        checked: {
            color: colors.accent.tertiary,
            borderColor: brandColors.grey,
        },
    },

    radioButtons: {
        default: radioButtonMixin({
            defaultLabel: {
                backgroundColor: brandColors.voltage,
                boxShadow: `0 0 0 0.4rem ${colors.accent.tertiary}`,
            },
            checkedLabel: {
                boxShadow: `0 0 0 0.4rem ${colors.accent.tertiary}`,
            },
        }),
        boolean: radioButtonMixin({
            checkedLabel: {
                color: colors.text.dark,
            },
        }),
    },

    dropdown: {
        primary: dropdownMixin({
            color: white,
            backgroundColor: colors.background.primary,
            borderBottom: `0.2rem solid ${white}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
        }),
        secondary: dropdownMixin({
            color: white,
            backgroundColor: colors.background.secondary,
            borderBottom: `0.2rem solid ${white}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
        }),
        tertiary: dropdownMixin({
            color: white,
            backgroundColor: colors.background.secondary,
            borderBottom: `0.2rem solid ${white}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
        }),
        quintuple: dropdownMixin({
            color: white,
            backgroundColor: colors.background.primary,
            borderBottom: `0.3rem solid ${white}`,
            iconRight: '0',
            iconTop: '2.7rem',
            iconWidth: '3rem',
            iconHeight: '3rem',
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
        }),
    },

    input: {
        primary: inputMixin({
            color: white,
            backgroundColor: colors.background.primary,
            boxShadow: `0 0 0 5rem ${colors.background.primary}`,
            borderBottom: `0.2rem solid ${brandColors.ice}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
            placeholderColor: addAlpha(white, 0.7),
            labelColor: white,
            prefixColor: white,
        }),
        secondary: inputMixin({
            backgroundColor: colors.background.secondary,
            boxShadow: `0 0 0 5rem ${colors.background.secondary}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
        }),
        tertiary: inputMixin({
            backgroundColor: colors.background.secondary,
            boxShadow: `0 0 0 5rem ${colors.background.secondary}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
        }),
        quaternary: inputMixin({
            color: black,
            backgroundColor: brandColors.ice,
            boxShadow: ` 0 0 0 5rem ${brandColors.ice}`,
            borderBottom: `0.2rem solid ${black}`,
            focusBorderBottom: `0.2rem solid ${colors.accent.tertiary}`,
            placeholderColor: addAlpha(black, 0.7),
        }),
        quintuple: inputMixin({
            color: black,
            backgroundColor: white,
            boxShadow: ` 0 0 0 5rem ${white}`,
            borderBottom: `0.2rem solid ${black}`,
            focusBorderBottom: `0.2rem solid ${brandColors.sohoLights}`,
            inputFontWeight: 500,
            placeholderColor: addAlpha(black, 0.7),
            placeholderFontSize: '1.5rem',
            placeholderFontStyle: 'italic',
            labelFontSize: '1.5rem',
            labelFontWeight: 400,
            labelColor: black,
        }),
    },

    materialInput: {
        primary: inputMixin({
            labelColor: white,
            labelFontSize: '1.8rem',
            color: white,
            inputFontSize: '2rem',
            backgroundColor: brandColors.siphon,
            outlineColor: brandColors.ink,
            outlineFocusedColor: brandColors.voltage,
            hoverColor: brandColors.sohoLights,
        }),
    },

    dialog: {
        default: {
            backdrop: {
                backgroundColor: black,
                opacity: 0.8,
            },
            backgroundColor: white,
            color: black,
        },
        dark: {
            backdrop: {
                backgroundColor: black,
                opacity: 0.8,
            },
            backgroundColor: colors.background.secondary,
            color: white,
        },
        transparent: {
            backdrop: {
                backgroundColor: black,
                opacity: 0.8,
            },
            backgroundColor: 'transparent',
            color: white,
        },
    },

    unitRatesGraph: {
        ratesLine: brandColors.voltage,
        averageRateLine: colors.accent.primary,
        headerBackground: white,
        button: {
            primaryColor: brandColors.voltage,
            secondaryColor: colors.text.dark,
        },
    },

    alert: {
        success: {
            backgroundColor: colors.action.success,
        },
        warning: {
            backgroundColor: colors.action.warning,
        },
        error: {
            backgroundColor: colors.action.error,
        },
        info: {
            backgroundColor: colors.accent.tertiary,
        },
    },

    technicalData: {
        borderColor: brandColors.dustySky,
        highlight: colors.accent.secondary,
    },

    stripeInput: {
        backgroundColor: colors.background.primary,
        borderColor: colors.grey.base,
        color: brandColors.ice,
        autofill: 'rgb(250, 255, 189)',
        focus: {
            borderColor: brandColors.ice,
            color: white,
        },
        invalid: {
            borderColor: colors.action.error,
            color: colors.action.error,
        },
        placeholder: {
            color: colors.grey.base,
        },
    },

    pagination: paginationMixin({
        borderColor: colors.accent.tertiary,
        active: colors.accent.primary,
    }),
    tariffSetup: {
        link: {
            background: colors.accent.primary,
            color: colors.background.secondary,
        },
        tariffOption: {
            standard: {
                primaryColor: colors.accent.secondary,
                color: colors.background.secondary,
                pill: colors.accent.tertiary,
                pillColor: white,
            },
            greenest: {
                primaryColor: colors.accent.secondary,
                color: colors.background.primary,
                pill: colors.action.success,
                pillColor: colors.background.secondary,
            },
            cheapest: {
                primaryColor: colors.accent.secondary,
                color: colors.background.primary,
                pill: colors.accent.primary,
                pillColor: colors.background.primary,
            },
        },
        estimated: {
            buttonColor: colors.accent.tertiary,
            buttonTextColor: colors.background.secondary,
            fontColor: colors.background.secondary,
            headingColor: colors.accent.secondary,
            highlight: colors.action.success,
        },
    },
    pill: {
        primary: pillMixin({
            backgroundColor: colors.accent.primary,
        }),
        secondary: pillMixin({
            backgroundColor: colors.accent.secondary,
        }),
        tertiary: pillMixin({
            backgroundColor: colors.accent.tertiary,
        }),
        green: pillMixin({
            backgroundColor: colors.action.success,
        }),
    },
    switch: switchMixin({
        thumbBackgroundColor: white,
        trackBackgroundColor: colors.accent.tertiary,
    }),
    sidebar: sidebarMixin({
        backgroundColor: colors.background.primary,
        toolbarBackgroundColor: colors.background.secondary,
    }),
    tile: {
        primary: tileMixin({
            backgroundColor: colors.background.secondary,
            color: white,
        }),
        secondary: tileMixin({
            backgroundColor: colors.background.primary,
            color: white,
        }),
    },
    billsPage: billsMixin({
        billBackgroundColour: colors.background.secondary,
    }),
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
    },

    messageCard: {
        color: colors.text.secondary,
        boxShadow: 'inset 0 0.2rem 0.4rem 0.2rem rgba(4, 5, 39, 0.32)',
        envelope: colors.accent.tertiary,
        borderColor: brandColors.ink,
    },

    attachment: {
        backgroundColor: colors.accent.secondary,
    },

    paragraphTitle: {
        letterSpacing: '-0.01rem',
    },

    readyToGo: readyToGoMixin({ successHeaderDisplay: 'block' }),

    accountLookup: accountLookupMixin({ imgWrapperDisplay: 'block' }),

    debtRepaymentOptions: debtRepaymentOptionsMixin({
        optionFontColor: white,
        optionSecondaryFontColor: colors.nav.link,
        primaryBackgroundColor: colors.background.secondary,
        secondaryButtonBackgroundColor: colors.accent.tertiary,
    }),

    faqs: {
        buttonFocusBorderColor: white,
        hoverBackgroundColor: '#1e2842',
        headerColor: colors.accent.tertiary,
        searchInputBackgroundColor: '#ececec',
        emergencyLinkColor: colors.nav.linkDark,
    },

    faqsFeedbackMessage: {
        borderColor: brandColors.green,
        backgroundColor: colors.background.secondary,
        overlayBackgroundColor: addAlpha(colors.background.secondary, 0.9),
    },

    emojiButton: {
        backgroundColor: brandColors.hemocyanin,
        borderColor: brandColors.ink,
        hoverBackgroundColor: brandColors.ink,
        color: white,
        fontWeight: 500,
    },

    intelligentOctopus: {
        characterHighlightBackgroundColor: brandColors.voltage,
        characterHighlightColor: black,
        successBackgroundColor: brandColors.hemocyanin,
        successTitleColor: brandColors.voltage,
        counterBorderColor: brandColors.ink,
    },

    heatPumps: {
        accent: brandColors.voltage,
    },

    smartMeterPopup: {
        backgroundColor: colors.background.primary,
        foregroundColor: colors.background.secondary,
        backgroundImage: `url(${SmartModalBackground})`,
        padding: '6.4rem',
        border: `6px solid ${brandColors.electricPurple}`,
    },
    actionCard: {
        backgroundColor: brandColors.blueberry,
        borderColor: brandColors.blueberry,
    },
};
