export default ({
    backgroundColor,
    facebook = {},
    twitter = {},
    whatsapp = {},
    email = {},
}) => ({
    referralLinks: {
        backgroundColor,
    },
    shareLink: {
        facebook: getIconStyles(facebook),
        twitter: getIconStyles(twitter),
        whatsapp: getIconStyles(whatsapp),
        email: getIconStyles(email),
    },
});

const getIconStyles = ({ backgroundColor, backgroundImage }) => ({
    backgroundColor,
    backgroundImage,
});
