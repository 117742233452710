export default ({
    textTransform,
    letterSpacing,
    fontSize,
    fontSizeSmallScreens,
    fontWeight,
    padding,
    borderRadius,
    deselectedColor,
    deselectedBackgroundColor,
    deselectedBorder,
    selectedColor,
    selectedBackgroundColor,
    selectedBorder,
    selectedBoxShadow,
    joinedMargin,
    joinedFirstBorder,
    joinedLastBorder,
    joinedBorderRadius,
    joinedDeselectedBorder,
    containerBorder = '0',
    containerBorderRadius = '0',
    containerBoxSizing = 'border-box',
    border = '0',
}) => ({
    textTransform: textTransform || 'none',
    letterSpacing: letterSpacing || 'normal',
    fontSize: fontSize || '1.8rem',
    fontWeight: fontWeight || '400',
    fontSizeSmallScreens: fontSizeSmallScreens || '1.6rem',
    padding: padding,
    border: border,
    borderRadius,
    deselected: {
        color: deselectedColor,
        backgroundColor: deselectedBackgroundColor,
        border: deselectedBorder,
    },
    selected: {
        color: selectedColor,
        border: selectedBorder,
        backgroundColor: selectedBackgroundColor,
        boxShadow: selectedBoxShadow || 'none',
    },
    joined: {
        margin: joinedMargin,
        borderRadius: joinedBorderRadius || borderRadius,
        firstChild: {
            borderRadius: joinedFirstBorder,
        },
        lastChild: {
            borderRadius: joinedLastBorder,
        },
        deselectedBorder: joinedDeselectedBorder || deselectedBorder,
    },
    container: {
        border: containerBorder,
        borderRadius: containerBorderRadius,
        boxSizing: containerBoxSizing,
    },
});
