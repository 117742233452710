export default ({
    backgroundColor,
    contentBackgroundColor,
    borderColor,
    borderColorSelected,
    contentBackgroundColorSelected,
}) => ({
    backgroundColor,
    contentBackgroundColor,
    borderColor,
    borderColorSelected,
    contentBackgroundColorSelected,
});
