import { logEvent, ELJourney, ELType, ELAnonymousEvent, } from 'octo-tools/logger';
export var validGtmUserPref;
(function (validGtmUserPref) {
    validGtmUserPref["ANALYTICS"] = "analytics_and_customisation";
    validGtmUserPref["MARKETING"] = "social_networking_and_marketing";
    validGtmUserPref["ANALYTICS_AND_MARKETING"] = "analytics_and_customisation,social_networking_and_marketing";
    validGtmUserPref["NONE"] = "none";
})(validGtmUserPref || (validGtmUserPref = {}));
var consentModeValues;
(function (consentModeValues) {
    consentModeValues["GRANTED"] = "granted";
    consentModeValues["DENIED"] = "denied";
})(consentModeValues || (consentModeValues = {}));
const preferenceLogType = {
    analytics_and_customisation: 'analytics',
    social_networking_and_marketing: 'marketing',
    'analytics_and_customisation,social_networking_and_marketing': 'all',
    none: 'none',
};
export const cookies = [
    {
        disabled: false,
        isCheckedByDefault: false,
        label: 'Analytics & customisation',
        id: 'analytics_and_customisation',
        tooltip: 'These ones help us understand how customers are using our site. They help us build better features and tailor our content for different people.',
    },
    {
        disabled: false,
        isCheckedByDefault: false,
        label: 'Marketing & social media',
        id: 'social_networking_and_marketing',
        tooltip: 'These ones help us reach you on social media or other websites to help you with queries or present relevant offers.',
    },
];
export const prefsToPrefString = ({ preferences: { analytics_and_customisation, social_networking_and_marketing, }, }) => {
    if (analytics_and_customisation && social_networking_and_marketing) {
        return validGtmUserPref['ANALYTICS_AND_MARKETING'];
    }
    if (analytics_and_customisation) {
        return validGtmUserPref['ANALYTICS'];
    }
    if (social_networking_and_marketing) {
        return validGtmUserPref['MARKETING'];
    }
    return validGtmUserPref['NONE'];
};
const gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
};
export const sendUserPrefUpdateToGTM = ({ value, callback, automated, repeat, }) => {
    let eventStr;
    if (repeat) {
        eventStr = ELAnonymousEvent.CookiePreferenceRepeat;
    }
    else if (automated) {
        eventStr = ELAnonymousEvent.CookiePreferenceNavigation;
    }
    else {
        eventStr = ELAnonymousEvent.CookiePreferenceAction;
    }
    // We keep "none" in the cookie for easy evaluation, and send this to the logger
    // We convert to an empty string for GTM, as it parses this as a preference for no optional cookies
    const valueForLogger = preferenceLogType[value];
    const valueForGtm = value.replace('none', '');
    try {
        logEvent({
            eventType: ELType.AnonymousBrowserEvent,
            journey: ELJourney.CookiePreferences,
            event: eventStr,
            action: valueForLogger,
        });
    }
    catch (e) {
        console.error('Logger unavailable');
    }
    if (Array.isArray(window.dataLayer)) {
        window.dataLayer.push({
            event: 'userPrefUpdate',
            cookieAccepted: valueForGtm,
        });
        // Push consent mode information to the dataLayer
        // on-preference send (matching other consent options)
        const analyticsConsentSetting = [
            validGtmUserPref.ANALYTICS,
            validGtmUserPref.ANALYTICS_AND_MARKETING,
        ].includes(value)
            ? consentModeValues.GRANTED
            : consentModeValues.DENIED;
        const adConsentSetting = [
            validGtmUserPref.MARKETING,
            validGtmUserPref.ANALYTICS_AND_MARKETING,
        ].includes(value)
            ? consentModeValues.GRANTED
            : consentModeValues.DENIED;
        gtag('consent', 'update', {
            ad_storage: adConsentSetting,
            ad_user_data: adConsentSetting,
            ad_personalization: adConsentSetting,
            analytics_storage: analyticsConsentSetting,
        });
    }
    // Call the callback, which sets a preference cookie
    // Only re-set the cookie (to last for another year) on explicit user actions
    callback(value, !automated);
};
