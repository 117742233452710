export default ({
    color,
    backgroundColor,
    borderBottom,
    boxShadow,
    placeholderColor,
    placeholderFontSize,
    placeholderFontStyle,
    inputFontWeight,
    inputFontSize,
    labelFontSize,
    labelFontWeight,
    focusBorderBottom,
    labelColor,
    helpTextColor,
    hoverColor,
    prefixColor,
    validBorderBottomColor,
    outlineColor,
    outlineFocusedColor,
}) => ({
    color: color,
    backgroundColor: backgroundColor,
    borderBottom: borderBottom,
    boxShadow: boxShadow,
    fontWeight: inputFontWeight,
    fontSize: inputFontSize,
    outlineColor: outlineColor,
    focus: {
        borderBottom: focusBorderBottom,
        outline: outlineFocusedColor,
    },
    hover: {
        color: hoverColor,
    },
    placeholder: {
        color: placeholderColor,
        size: placeholderFontSize,
        style: placeholderFontStyle,
    },
    label: {
        color: labelColor,
        size: labelFontSize,
        weight: labelFontWeight,
    },
    prefix: {
        color: prefixColor,
    },
    helpText: {
        color: helpTextColor,
    },
    valid: {
        borderBottomColor: validBorderBottomColor,
    },
});
