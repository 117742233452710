export default ({
    backgroundColor,
    border,
    borderRadius,
    boxShadow,
    label,
}) => ({
    backgroundColor: backgroundColor || '#ffffff',
    border: border || 'none',
    borderRadius: borderRadius || '0.4rem',
    boxShadow: boxShadow || 'none',
    label: label || '#000000',
});
