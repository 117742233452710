export default ({
    mascotDisplay,
    rowBackgroundColor,
    oddRowBackgroundColor,
    electricityIcon = {},
    gasIcon = {},
    rowButtonColor,
    dateToggleButtonDisabledBackgroundColor,
    metersBackgroundColor,
    metersBorderColor,
    activeMeter = {},
    notFoundBackground = '',
}) => ({
    consumptionList: {
        mascot: {
            display: mascotDisplay,
        },
    },
    consumptionRow: {
        backgroundColor: rowBackgroundColor,
        oddBackgroundColor: oddRowBackgroundColor,
        icon: {
            electricity: iconStyles(electricityIcon),
            gas: iconStyles(gasIcon),
        },
        button: {
            color: rowButtonColor,
        },
    },
    dateToggleButtonDisabled: {
        backgroundColor: dateToggleButtonDisabledBackgroundColor,
    },
    meters: {
        backgroundColor: metersBackgroundColor,
        borderColor: metersBorderColor,
        active: activeMeterStyles(activeMeter),
        meterName: {
            electricity: iconStyles(electricityIcon),
            gas: iconStyles(gasIcon),
        },
    },
    consumptionHome: {
        notFoundBackground,
    },
});

const iconStyles = ({ beforeBackgroundColor, afterBackgroundImage }) => ({
    beforeBackgroundColor,
    afterBackgroundImage,
});

const activeMeterStyles = ({ borderColor }) => ({
    borderColor,
});
