export default ({
    defaultCheckbox = {},
    focusedCheckbox = {},
    checkedCheckbox = {},
}) => ({
    default: getCheckboxStyles(defaultCheckbox),
    focused: getCheckboxStyles(focusedCheckbox),
    checked: getCheckboxStyles(checkedCheckbox),
});
const getCheckboxStyles = ({
    textColor,
    color,
    borderColor,
    borderRadius,
    backgroundColor,
    boxShadow,
}) => ({
    textColor,
    color,
    borderColor,
    borderRadius,
    backgroundColor,
    boxShadow,
});
