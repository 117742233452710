export default ({ wellBackgroundColor, linkColor, tileLinkColor }) => ({
    well: {
        backgroundColor: wellBackgroundColor,
    },
    link: {
        color: linkColor,
    },
    tileLink: {
        color: tileLinkColor,
    },
});
