export default ({
    error,
    warning,
    success,
    link,
    linkHover,
    linkDark,
    linkDarkHover,
    backgroundPrimary,
    backgroundSecondary,
    textPrimary,
    textSecondary,
    textLight,
    textDark,
    accentPrimary,
    accentSecondary,
    accentTertiary,
    greyBase,
    greyDark,
    greyMedium,
    greyLight,
}) => ({
    social: {
        facebook: '#0866ff',
        twitter: '#000',
        whatsapp: '#25d366',
        email: accentSecondary,
    },
    action: {
        error,
        warning,
        success,
    },
    nav: {
        link,
        linkHover,
        linkDark,
        linkDarkHover,
    },
    background: {
        primary: backgroundPrimary,
        secondary: backgroundSecondary,
    },
    text: {
        primary: textPrimary,
        secondary: textSecondary,
        light: textLight,
        dark: textDark,
    },
    accent: {
        primary: accentPrimary,
        secondary: accentSecondary,
        tertiary: accentTertiary,
    },
    grey: {
        base: greyBase,
        dark: greyDark,
        medium: greyMedium,
        light: greyLight,
    },
});
