export default ({
    fontSize,
    lineHeight,
    prettyPrice = {},
    single = {},
    cost = {},
    savings = {},
    fixedPrice = {},
    projection = {},
    card = {},
    co2Card = {},
    cardButton,
    tariffInfoButton = {},
    cardContent = {},
}) => ({
    fontSize,
    lineHeight,
    prettyPrice: getPrettyPriceStyles(prettyPrice),
    single: getSingleTariffStyles(single),
    cost: getCostStyles(cost),
    savings: getSavingsStyles(savings),
    fixedPrice: getFixedPriceStyles(fixedPrice),
    projection: getProjectionStyles(projection),
    card: getCardStyles(card),
    co2Card: getCardStyles(co2Card),
    cardButton: {
        ...cardButton,
    },
    cardContent: getCardContentStyles(cardContent),
    tariffInfoButton: getTariffInfoButtonStyles(tariffInfoButton),
});

const getPrettyPriceStyles = ({ fontSize, forcedColor }) => ({
    fontSize,
    forced: {
        color: forcedColor,
    },
});

const getSingleTariffStyles = ({
    display,
    flexDirection,
    justifyContent,
    alignItems,
    width,
    padding,
    paddingMd,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    backgroundSizeMd,
    border,
    borderRadius,
    descriptionMaxWidth,
    descriptionMargin,
}) => ({
    display,
    flexDirection,
    justifyContent,
    alignItems,
    width,
    padding,
    paddingMd,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    backgroundSizeMd,
    border,
    borderRadius,
    description: {
        maxWidth: descriptionMaxWidth,
        margin: descriptionMargin,
    },
});

const getCostStyles = ({
    desktopPadding,
    desktopMargin,
    mobileMargin,
    lineHeight,
    fontSize,
}) => ({
    desktopPadding,
    desktopMargin,
    mobileMargin,
    lineHeight,
    fontSize,
});

const getSavingsStyles = ({ margin, fontSize }) => ({
    margin,
    fontSize,
});

const getFixedPriceStyles = ({
    paddingBottom,
    margin,
    fontSize,
    lineHeight,
}) => ({
    paddingBottom,
    margin,
    fontSize,
    lineHeight,
});

const getProjectionStyles = ({
    width,
    defaultPadding,
    defaultBackgroundColor,
    lightBackgroundColor,
}) => ({
    width,
    default: {
        padding: defaultPadding,
        backgroundColor: defaultBackgroundColor,
    },
    light: {
        backgroundColor: lightBackgroundColor,
    },
});

const getCardStyles = ({
    color,
    backgroundColor,
    ignoreButtonOverlap,
    personalProjection,
    amountLetterSpacing,
}) => ({
    color,
    backgroundColor,
    ignoreButtonOverlap,
    personalProjection,
    amount: {
        letterSpacing: amountLetterSpacing,
    },
});

const getTariffInfoButtonStyles = ({
    color,
    backgroundColor,
    borderColor,
}) => ({
    color,
    backgroundColor,
    borderColor,
});

const getCardContentStyles = ({ highlightColor }) => ({
    highlightColor,
});
