export default ({
    color,
    backgroundColor,
    badgeColor,
    marginBottom,
    borderRadius,
    border,
    hoverColor = color,
}) => ({
    color,
    backgroundColor,
    badgeColor,
    marginBottom,
    borderRadius,
    border,
    hoverColor,
});
