export default ({
    lastReadingBorder,
    lastReadingColor,
    lastReadingBackgroundColor,
    lastReadingInfo = {},
    lastReadingFigure = {},
    fieldColor,
    fieldBackgroundColor,
    newReadingFieldAfter = {},
    newReadingAfter = {},
    tooltipAnimation = {},
    well = {},
    modalHr = {},
}) => ({
    add: {
        lastReading: {
            border: lastReadingBorder,
            color: lastReadingColor,
            backgroundColor: lastReadingBackgroundColor,
            info: lastReadingInfoStyles(lastReadingInfo),
            figure: lastReadingFigureStyles(lastReadingFigure),
        },
        newReading: {
            field: {
                color: fieldColor,
                backgroundColor: fieldBackgroundColor,
                after: newReadingFieldAfterStyles(newReadingFieldAfter),
            },
            after: newReadingAfterStyles(newReadingAfter),
        },
        tooltip: {
            animation: tooltipAnimationStyles(tooltipAnimation),
        },
        well: wellStyles(well),
    },
    modals: {
        hr: hrStyles(modalHr),
    },
});

const lastReadingInfoStyles = ({ firstMarginBottom, fontSize }) => ({
    firstMarginBottom,
    fontSize,
});

const lastReadingFigureStyles = ({ color, fontSize, afterColor }) => ({
    color,
    fontSize,
    afterColor,
});

const newReadingFieldAfterStyles = ({ right, color }) => ({
    right,
    color,
});

const newReadingAfterStyles = ({ color }) => ({
    color,
});

const tooltipAnimationStyles = ({ backgroundColor }) => ({
    backgroundColor,
});

const wellStyles = ({ backgroundColor }) => ({
    backgroundColor,
});

const hrStyles = ({ borderColor }) => ({
    borderColor,
});
