export default ({
    optionFontColor,
    optionSecondaryFontColor,
    primaryBackgroundColor,
    secondaryButtonBackgroundColor,
}) => ({
    optionFontColor,
    optionSecondaryFontColor,
    primaryBackgroundColor,
    secondaryButtonBackgroundColor,
});
