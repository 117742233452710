export default ({
    backgroundColor,
    sectionHeaderColour,
    sectionBackgroundColour,
    pageHeaderTitle = {},
    pageHeaderSubtitle = {},
    sectionTitle = {},
    sectionSubtitle = {},
    padding = '0',
    borderTop = 'none',
    directDebitInstructionLogo,
    directDebitInstructionLogoInverse = '',
    termsAndConditionsBorder,
    termsBackgroundColour,
}) => ({
    backgroundColor,
    review: {
        pageHeader: {
            title: pageHeaderTitleStyles(pageHeaderTitle),
            subtitle: pageHeaderSubtitleStyles(pageHeaderSubtitle),
        },
        section: {
            backgroundColor: sectionBackgroundColour || backgroundColor,
            padding,
            borderTop,
            header: {
                color: sectionHeaderColour,
                title: sectionTitleStyles(sectionTitle),
                subtitle: subsectionTitleStyles(sectionSubtitle),
            },
        },
        directDebitInstruction: {
            backgroundImage: `url(${directDebitInstructionLogo})`,
            backgroundImageInverse: `url(${directDebitInstructionLogoInverse})`,
        },
        termsAndConditions: {
            border: termsAndConditionsBorder,
            backgroundColor:
                termsBackgroundColour ||
                sectionBackgroundColour ||
                backgroundColor,
        },
    },
});

const sectionTitleStyles = ({
    marginTop,
    fontSize,
    lineHeight,
    fontWeight = 800,
}) => ({
    marginTop,
    fontSize,
    lineHeight,
    fontWeight,
});

const subsectionTitleStyles = ({ fontSize, lineHeight }) => ({
    fontSize,
    lineHeight,
});

const pageHeaderTitleStyles = ({
    fontSize,
    marginBottom = '0.8rem',
    fontWeight = 800,
    lineHeight = '3.4rem',
    letterSpacing = 'inherit',
}) => ({
    marginBottom,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
});

const pageHeaderSubtitleStyles = ({
    fontSize,
    marginBottom,
    fontWeight = 800,
    lineHeight,
    letterSpacing,
}) => ({
    marginBottom,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
});
