import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { CookiePreferences } from '@krakentech/coral-organisms';
import { cookies, prefsToPrefString, sendUserPrefUpdateToGTM, validGtmUserPref, } from 'octo-core/utils';
import { octopusTheme } from 'octo-styles/themes';
import { ThemeProvider } from 'octo-tools/theme';
import { initLogger } from 'octo-tools/logger';
import { octopusEn } from 'octo-translations/onboarding';
initLogger({ service: 'consumersite-octopus' });
const App = () => {
    const [dismissedThisSession, setDismissedThisSession] = useState(false);
    const [prefSentThisSession, setPrefSentThisSession] = useState(false);
    // Check if a preference cookie already exists
    const existingPreferenceCookie = octo.utils.getCookie('dismissCookiePreferencesMessage');
    const dismissBanner = (preference, resetCookie = true) => {
        if (resetCookie) {
            octo.utils.setCookie('dismissCookiePreferencesMessage', preference, 364); // Save the preference to cookies for 1 year
        }
        setDismissedThisSession(true); // Hide the banner now
    };
    // If a preference is already saved to cookies, and we haven't yet sent it to GTM this session, send it
    if (existingPreferenceCookie && !prefSentThisSession) {
        sendUserPrefUpdateToGTM({
            value: octo.utils.getCookie('dismissCookiePreferencesMessage'),
            callback: dismissBanner,
            automated: true,
            repeat: true,
        });
        setPrefSentThisSession(true);
    }
    // If the user has already dismissed the banner, do nothing more
    if (dismissedThisSession || existingPreferenceCookie) {
        return null;
    }
    /*
     * Wrap localStorage interface in try/catch in case we're unable to access it.
     * This can happen when cookies are blocked for our site in the browser.
     * In this case, we just don't track whether a user has seen the banner before.
     */
    try {
        /*
         * If the user has already seen the banner before and not made a choice
         * Treat continued navigation as acceptance and send the appropriate preference
         */
        if (window.localStorage.getItem('shownCookiePreferences') === 'true') {
            sendUserPrefUpdateToGTM({
                value: validGtmUserPref['ANALYTICS_AND_MARKETING'],
                callback: dismissBanner,
                automated: true,
            });
            setPrefSentThisSession(true);
            return null;
        }
        // Finally set a flag to say that the user has seen the banner this session
        window.localStorage.setItem('shownCookiePreferences', 'true');
    }
    catch (error) {
        console.error('Unable to access localStorage to get or set cookie banner visibility flag', error);
    }
    return (_jsx(IntlProvider, { locale: "en", messages: octopusEn, children: _jsx(ThemeProvider, { theme: octopusTheme, children: _jsxs(CookiePreferences, { initialDisplayMode: "banner", children: [_jsx(CookiePreferences.CookieBanner, { header: _jsx(CookiePreferences.CookieBannerDefaultHeader, { recipeUrl: "https://acupofteaandcake.com/the-blog/funfetti-sandwich-cookies/" }), onAcceptEverything: () => {
                            sendUserPrefUpdateToGTM({
                                value: validGtmUserPref['ANALYTICS_AND_MARKETING'],
                                callback: dismissBanner,
                            });
                            setPrefSentThisSession(true);
                        } }), _jsx(CookiePreferences.CookieSelection, { cookies: cookies, onPreferencesChosen: (preferences) => {
                            sendUserPrefUpdateToGTM({
                                value: prefsToPrefString({ preferences }),
                                callback: dismissBanner,
                            });
                            setPrefSentThisSession(true);
                        } })] }) }) }));
};
export default App;
