export default ({ defaultLabel = {}, checkedLabel = {} }) => ({
    label: {
        default: getLabelStyles(defaultLabel),
        checked: getLabelStyles(checkedLabel),
    },
});

const getLabelStyles = ({ backgroundColor, boxShadow, color }) => ({
    backgroundColor,
    boxShadow,
    color,
});
