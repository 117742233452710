export default ({
    backgroundColor,
    color,
    link = {},
    borderRadius,
    borderColor,
}) => ({
    backgroundColor,
    color,
    link: {
        color: link.color,
    },
    borderRadius,
    borderColor,
});
