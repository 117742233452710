export default ({
    paddingTop,
    backgroundColor,
    well = {},
    card = {},
    overview = {},
    personalProjection = {},
    explanationMarginTop,
}) => ({
    paddingTop,
    backgroundColor,
    well: getWellStyles(well),
    card: getCardStyles(card),
    overview: getOverviewStyles(overview),
    personalProjection: getPersonalProjectionStyles(personalProjection),
    explanation: {
        marginTop: explanationMarginTop,
    },
});

const getWellStyles = ({ padding, backgroundColor }) => ({
    padding,
    backgroundColor,
});

const getCardStyles = ({
    display,
    flexDirection,
    alignItems,
    textAlign,
    position,
    width,
    maxWidth,
    minHeight,
    padding,
    margin,
    color,
    backgroundColor,
    borderRadius,
    overviewPadding,
    personalProjectionMarginTop,
    personalProjectionBackgroundImage,
    personalProjectionBackgroundPosition,
    personalProjectionBackgroundRepeat,
    personalProjectionBackgroundSize,
    personalProjectionBackgroundPositionMd,
    personalProjectionBackgroundSizeMd,
}) => ({
    display,
    flexDirection,
    alignItems,
    textAlign,
    position,
    width,
    maxWidth,
    minHeight,
    padding,
    margin,
    color,
    backgroundColor,
    borderRadius,
    overview: {
        padding: overviewPadding,
    },
    personalProjection: {
        marginTop: personalProjectionMarginTop,
        backgroundImage: personalProjectionBackgroundImage,
        backgroundPosition: personalProjectionBackgroundPosition,
        backgroundRepeat: personalProjectionBackgroundRepeat,
        backgroundSize: personalProjectionBackgroundSize,
        backgroundPositionMd: personalProjectionBackgroundPositionMd,
        backgroundSizeMd: personalProjectionBackgroundSizeMd,
    },
});

const getOverviewStyles = ({
    width,
    position,
    zIndex,
    padding,
    marginBottom,
    background,
    border,
    borderRadius,
    currentTariffPadding,
    currentTariffPaddingMd,
    currentAmountWidth,
    currentAmountPadding,
    currentAmountMarginBottom,
    currentAmountFontSize,
    currentAmountBackgroundColor,
    currentAmountBorderRadius,
    currentAmountMarginTop = '0',
}) => ({
    width,
    position,
    zIndex,
    padding,
    marginBottom,
    background,
    border,
    borderRadius,
    currentTariff: {
        padding: currentTariffPadding,
        paddingMd: currentTariffPaddingMd,
    },
    currentAmount: {
        width: currentAmountWidth,
        padding: currentAmountPadding,
        marginBottom: currentAmountMarginBottom,
        fontSize: currentAmountFontSize,
        backgroundColor: currentAmountBackgroundColor,
        borderRadius: currentAmountBorderRadius,
        marginTop: currentAmountMarginTop,
    },
});

const getPersonalProjectionStyles = ({
    position,
    width,
    minHeight,
    padding,
    margin,
    color,
    backgroundColor,
    borderRadius,
    border = 'none',
}) => ({
    position,
    width,
    minHeight,
    padding,
    margin,
    color,
    backgroundColor,
    borderRadius,
    border,
});
