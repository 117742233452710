import { darken } from 'polished';

export default ({
    color,
    padding,
    margin,
    background,
    backgroundImage,
    boxShadow,
    border,
    borderRadius,
    fontSize,
    fontSizeSmallScreens,
    fontWeight,
    textTransform = 'none',
    focus = {},
    hover = {},
    after,
    disabled = {},
}) => ({
    color,
    padding,
    margin,
    background,
    backgroundImage,
    boxShadow,
    border,
    borderRadius,
    fontSize,
    fontSizeSmallScreens,
    fontWeight,
    textTransform,
    hover: {
        background: darken(0.1, background),
        ...hover,
    },
    focus,
    after,
    disabled,
});
