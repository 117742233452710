export default {
    brand: 'Octopus Energy',
    supportEmailAddress: 'hello@octopus.energy',
    prepaymentEmailAddress: 'prepayment@octopus.energy',
    'quote.bankAccountDetails.paymentMethodsAcceptedLink':
        '/help-and-faqs/categories/bills-and-payments/what-payment-methods-do-you-offer/',
    'createAccount.bankDetails.header': 'Good to know:',
    'createAccount.bankDetails': ` Your monthly costs
    are an estimate. We'll suggest adjustments as we get a better
    understanding of your energy use, to keep your account on track.`,
    'createAccount.thirdPartyMarketingOptIn.privacyPolicy.arsenal':
        'In addition, as you’ve selected our Arsenal tariff, we will share your details with The Arsenal Football Club PLC, who may also contact you by email, post or text. Is that ok?',
    'youShouldKnowModal.typeformUrl':
        'https://octopus.typeform.com/to/yWVcoCVB',
    'youShouldKnowModal.closeText':
        "I've read and understood the above, and I would still like a quote to switch to Octopus",
};
