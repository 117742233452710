export default ({
    configurableBackground,
    configurableTextColor,
    borderColor,
    borderWidth,
    pendingTextColor,
    pendingBorder,
    summaryBackground,
    summaryDarkOverlay,
    hyperlinkColor,
    supplyStartIcon,
    activityStreamColor,
    activityStreamBackgroundColor,
    transactionIconColorDark,
    transactionIconColorLight,
    consumptionDateRangeColor,
}) => ({
    activityStream: {
        backgroundColor: activityStreamBackgroundColor,
        color: activityStreamColor,
    },
    configurable: {
        background: configurableBackground,
        color: configurableTextColor,
    },
    border: {
        color: borderColor,
        width: borderWidth,
    },
    pending: {
        color: pendingTextColor,
        border: pendingBorder,
    },
    summary: {
        background: summaryBackground,
        darkOverlay: summaryDarkOverlay,
    },
    hyperlink: {
        color: hyperlinkColor,
    },
    supplyStart: {
        icon: supplyStartImgStyles(supplyStartIcon),
    },
    transactionIconColor: {
        dark: transactionIconColorDark,
        light: transactionIconColorLight,
    },
    consumption: {
        dateRangeColor: consumptionDateRangeColor,
    },
});

const supplyStartImgStyles = (backgroundImage) => ({
    backgroundImage,
});
