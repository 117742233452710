export default ({
    iconRight = '0',
    iconTop = '2.75rem',
    iconWidth = '4rem',
    iconHeight = '4rem',
    color,
    backgroundColor,
    borderBottom,
    labelTop = '0',
    focusBorderBottom = borderBottom,
}) => ({
    color: color,
    backgroundColor: backgroundColor,
    borderBottom: borderBottom,
    icon: {
        right: iconRight,
        top: iconTop,
        width: iconWidth,
        height: iconHeight,
    },
    label: {
        top: labelTop,
    },
    focus: {
        borderBottom: focusBorderBottom,
    },
});
