import { datadogLogs } from '@datadog/browser-logs';
const DATADOG_CLIENT_TOKEN = 'pub5ceceb10b1da744273518e870e36a8e3';
export const config = {
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    env: process.env.NODE_ENV === 'production' ? 'oegb-prod' : 'oegb-dev',
    forwardErrorsToLogs: false,
    sampleRate: 100,
    beforeSend: (log) => {
        // Remove stripe client secret from URL
        log.view.url = log.view.url.replace(/(?:payment_intent[^&]*)+/g, 'payment_intent_client_secret=REDACTED');
    },
};
export const initLogger = (customConfig) => datadogLogs.init(Object.assign(Object.assign({}, config), customConfig));
