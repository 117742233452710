import { jsx as _jsx } from "react/jsx-runtime";
// *** replacement for @babel/polyfill https://babeljs.io/docs/en/babel-polyfill
import 'core-js';
import 'regenerator-runtime/runtime';
// *** //
import { createRoot } from 'react-dom/client';
import App from './App';
const ScriptEl = document.getElementById('cookie-preferences-init-script');
const rootId = (ScriptEl === null || ScriptEl === void 0 ? void 0 : ScriptEl.dataset.rootId) || '';
const container = document.getElementById(rootId);
const root = createRoot(container);
root.render(_jsx(App, {}));
