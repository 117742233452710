export default ({
    constantineDisplay,
    link = {},
    beforeElectricity = {},
    electricityImg = {},
    gasImg = {},
    meterReadingRowBackgroundColor,
    meterReadingRowOddBackgroundColor,
    itemBackgroundColor,
    itemBorderColor,
    itemActive = {},
}) => ({
    meterReadingHistory: {
        constantine: {
            display: constantineDisplay,
        },
        modal: {
            link: linkStyles(link),
        },
    },
    meterReadingRow: {
        backgroundColor: meterReadingRowBackgroundColor,
        odd: {
            backgroundColor: meterReadingRowOddBackgroundColor,
        },
        before: {
            electricity: beforeElectricityStyles(beforeElectricity),
        },
        img: {
            electricity: electricityImgStyles(electricityImg),
            gas: gasImgStyles(gasImg),
        },
        link: linkStyles(link),
    },
    meters: {
        item: {
            border: itemBorderColor,
            backgroundColor: itemBackgroundColor,
            active: itemActiveStyles(itemActive),
            before: {
                electricity: beforeElectricityStyles(beforeElectricity),
            },
            img: {
                electricity: electricityImgStyles(electricityImg),
                gas: gasImgStyles(gasImg),
            },
        },
    },
});

const linkStyles = ({ color }) => ({
    color,
});

const beforeElectricityStyles = ({ backgroundColor }) => ({
    backgroundColor,
});

const electricityImgStyles = ({ backgroundImage }) => ({
    backgroundImage,
});

const gasImgStyles = ({ backgroundColor }) => ({
    backgroundColor,
});

const itemActiveStyles = ({ color, backgroundColor }) => ({
    color,
    backgroundColor,
});
